/** 
    * @license CC-BY-NC-SA-4.0
    * Copyright 2023-2023 Mednieki
    * SPDX-License-Identifier: CC-BY-NC-SA-4.0

    * Gold Country Lanes
    * Baby let's go hit the bowling alley
    * Air hockey table in the back break them quarters outs
    * Nostalgic bout them days
    * Wish I spent less time tryna work shit out
    * With you inside my head and lived the moments
    * That these photos capture
    * My souls in tatters she's the only one who truly loved me
*/

import App from "./components/app"
import { h, render } from "preact"

import { install } from "@twind/core"
import config from "../twind.config"

const container = document.getElementById("root") as HTMLElement

install(config)
render(<App />, container)
